import { es as storeEs }        from '@themes/store/frontend/locales/es';
import tribe                    from '@frontend/locales/es/tribe';
import contact                  from '@frontend/locales/es/contact';
import map                      from '@frontend/locales/es/map';
import rma                      from '@frontend/locales/es/rma';
import menuFooter               from '@frontend/locales/es/menu-footer';
import sideBarNewsletter        from '@frontend/locales/es/side-bar-newsletter';
import productSwatchInfo        from '@frontend/locales/es/product-swatch-info';
import neoactivesAttributes     from '@frontend/locales/es/neoactives-attributes';
import blog                     from '@frontend/locales/es/blog';
import neoactivesStaticTables   from '@frontend/locales/es/neoactives-static-tables';
import crossSellProducts        from '@frontend/locales/es/cross-sell-products';
import thanks                   from '@frontend/locales/es/thanks';
import wishlist                 from '@frontend/locales/es/wishlist';
import theme                    from '@frontend/locales/es/theme';
import safeBuy                    from '@frontend/locales/es/safe-buy';
import productViewInfo          from '@frontend/locales/es/product-view/info';
import productViewReviews       from '@frontend/locales/es/product-view/reviews';
import error                    from '@frontend/locales/es/error';
import looks                    from '@frontend/locales/es/looks';
import stock                    from '@frontend/locales/es/stock-notification';
import appointment              from '@frontend/locales/es/appointment';
import freeShippingStatusBar    from '@frontend/locales/es/free-shipping-status-bar';
import blackFriday              from '@frontend/locales/es/black-friday';
import socialRoom               from '@frontend/locales/es/social-room';
import breadcrumbs              from '@frontend/locales/es/breadcrumbs';
import calendly                 from '@frontend/locales/es/calendly';
import ethicalForm              from '@frontend/locales/es/ethical-form';

export const es = {
  ...storeEs,
  tribe,
  contact,
  map,
  rma,
  productSwatchInfo,
  neoactivesAttributes,
  blog,
  neoactivesStaticTables,
  menuFooter,
  sideBarNewsletter,
  crossSellProducts,
  thanks,
  wishlist,
  theme,
  stock,
  safeBuy,
  productViewInfo,
  productViewReviews,
  error,
  looks,
  appointment,
  freeShippingStatusBar,
  blackFriday,
  socialRoom,
  breadcrumbs,
  calendly,
  ethicalForm
}

export default es;
