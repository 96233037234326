export const pdf = {
  download:         'Descargar pdf',
  loading:          'Preparando pdf...',
  invoice:          'Factura',
  company:          'NEOLIFE MEDICAL GROUP, S.L.',
  addressData1:     'c/Velázquez, 94 · 28006 Madrid · Telf.: +34 91 732 59 50 · Oasis Business Center, CN-340, km.176 · 29602 Marbella. Málaga · Telf.: +34 95 130 44 88',
  addressData2:     'CIF: B-86.640.398. Registro Mercantil de Madrid. Tomo 30689. Sección 8, Folio 41, Hoja M 552218, Inscripción 1',
  email:            'info@neolifeclinic.com',
  website:          'www.neolifeclinic.com',
  invoiceNumber:    'Factura Nº',
  date:             'Fecha',
  vatId:            'DNI/CIF',
  phone:            'Tel',
  shippingAddress:  'Dirección de envío',
  billingAddress:   'Dirección de facturación',
  units:            'Unidades',
  unitPvp:          'Pvp unitario',
  subtotal:         'Subtotal',
  shippingTax:      'Gastos de envío y manipulación',
  tax:              'Impuestos',
  total:            'Total',
  paymentMethod:    'Forma de pago',
  endMessage:       'Gracias por haber confiado en nosotros. Esperamos verle pronto en su próxima cita en Etnia.'
}
export default pdf;
