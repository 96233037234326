export const searchBar = {
  placeholder:    'Buscar',
  search:         'Buscar',
  close:          'Cerrar',
  productResult:  "%1 producto/s encontrado/s para '%2'",
  postResult:     "%1 post/s encontrado/s para '%2'",
  recent:         "búsquedas recientes",
  popular:        "tendencias ahora",
  findYourStore:  "encuentra tu tienda",
  conditions:     "Condiciones",
  club:           "Club Lovely",
  legal:          "Bases legales",
  job:            "Empleo",
  warning:        "Aviso Legal",
  stores:         "Tiendas",
  faq:            "FAQ"
}

export default searchBar;
