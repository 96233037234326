import { createAccount as storeCreateAccount } from '@themes/store/frontend/locales/es/user/create-account';

export const createAccount = {
  ...storeCreateAccount,
  success:                    'Tu registro se ha realizado con éxito',
  pageTitle:                  'regístrate',
  pageDescription:            'para añadir tus productos favoritos a la wish list y conocer todas las ofertas',
  pageTitleFidelizados:       'regístrate',
  pageDescriptionFidelizados: 'para formar parte de la familia etnia',
  tagTitleFidelizados:        'Fidelizados',
  metaTitleFidelizados:       'Fidelizados',
  firstName:                  'Nombre',
  lastName:                   'Apellidos',
  birthday:                   'Fecha de nacimiento',
  taxvat:                     'DNI',
  subscribe:                  'Suscribete a nuestro boletín',
  nif:                        'NIF/NIE/CIF',
  address:                    'Dirección',
  telephone:                  'Teléfono',
  city:                       'Población',
  postCode:                   'C.P.',
  region:                     'Provincia',
  country:                    'País',
  personalInfo:               'Información Personal',
  accessInfo:                 'Información de acceso',
  email:                      'Email',
  password:                   'Contraseña',
  confirmPassword:            'Confirmar Contraseña',
  buttonLabel:                'Crear Cuenta',
  linkSignin:                 'Ya soy usuario',
  accept:                     'Acepto la ',
  privacyLink:                'política de privacidad',
  newsletter1:                'Me apunto',
  newsletter2:                ' al boletín de noticias',
  requiredFields:             '* Campos obligatorios',
  keepSession:                'Mantener la sesión iniciada',
  privacy1:                   'He ',
  privacy2:                   'leído y acepto',
  privacy3:                   ' las condiciones de la '
}

export default createAccount;
