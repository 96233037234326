export const accountMenu = {
  profile: 'Mi perfil',
  orders: 'Mis pedidos',
  wishlist: 'Wishlist',
  signout: 'Cerrar Sesión',
  signin: 'Iniciar Sesión',
  editProfile: 'editar perfil',
  description: 'Puedes consultar y modificar toda la información de tu perfil, así como cerrar sesión.',
  createAccount: 'Crear cuenta',
  myAccount:      'Mi cuenta'
}

export default accountMenu;
