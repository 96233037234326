
export const wishlist = {
    logInToAdd: 'Tienes que iniciar sesión en tu cuenta para poder añadir productos a tu wishlist.',
    logIn: 'iniciar sesión',
    addSuccess: '%1 añadido a la wishlist.',
    addError: 'Error al añadir %1 a la wishlist. Por favor, vuelve a intentarlo.',
    deleteSuccess: '%1 eliminado de la wishlist.',
    deleteError: 'Error al eliminar %1 de la wishlist. Por favor, vuelve a intentarlo.',
    goToWishlist: 'ir a mi wishlist'
  }
  
  export default wishlist;
  