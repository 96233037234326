export const neoactivesStaticTables = {
  mineral:{
    bones: 'Huesos',
    connectiveTissue: 'Tejido conectivo',
    immuneSystem: 'Sistema inmunitario',
    oxidativeDamage: 'Daño Oxidativo',
    bloodGlucose: 'Glucosa en sangre',
    redBloodCells:'Glóbulos rojos',
    tirednessFatigue:'Cansancio y fatiga',
    thyroid:'Tiroides',
    acidBase: 'Ácido base',
    testosterone: 'Testosterona',
    psychologicalFunction:'Función psicológica',
    calcium: 'Calcio',
    magnesium: 'Magnesio',
    zinc: 'Zinc',
    iron: 'Hierro',
    manganese: 'Manganeso',
    copper: 'Cobre',
    molybdenum: 'Molibdeno',
    iodine: 'Yodo',
    selenium: 'Selenio',
    chrome: 'Cromo',
    boron: 'Boro'
  },
  vitamine:{
    vitA: 'Vit. A',
    vitB:'Vit. B',
    vitB1:'Vit. B1',
    vitB2:'Vit. B2',
    vitB5:'Vit. B5',
    vitB6:'Vit. B6',
    vitB12:'Vit. B12',
    vitC:'Vit. C',
    vitD3:'Vit. D3',
    vitE:'Vit. E',
    vitK2:'Vit. K2',
    biotin: 'Biotina',
    choline:'Colina',
    folicAcid:'Ácido Fólico',
    tirednessFatigue:'Cansancio y fatiga',
    hormonalActivity:'Actividad hormonal',
    intellectualPerformance: 'Rendimiento intelectual',
    nervousSystem: 'Sistema nervioso',
    hairSkin:'Cabello y piel',
    lipids:'Lípidos',
    liverFunction:'Función hepática',
    homocysteine:'Homocisteína',
    immuneSystem:'Sistema inmunitario',
    vision:'Visión',
    oxidativeDamage: 'Daño Oxidativo',
    bones: 'Huesos',
    muscles: 'Músculos'
  }
}

export default neoactivesStaticTables;
