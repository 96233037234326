export const validator = {
  empty:                    "El campo esta vacío",
  email:                    "Este campo debe ser un email válido",
  phone:                    "Este campo debe ser un número de teléfono válido",
  letters:                  "Este campo sólo puede contener letras",
  lettersNumbers:           "Este campo sólo puede contener letras y números",
  numbers:                  "Este campo sólo puede contener números",
  float:                    "Este campo sólo puede contener números con decimales",
  validPassword:            "La contraseña debe contener al menos una letra mayúscula, una letra minúscula y un número, y debe tener almenos 8 caractéres de longitud",
  passwordnotmatch:         "Las contraseñas no coinciden",
  notoken:                  "No token provided",
  notEmpty:                 "Este campo no puede estar vacío",
  lettersWithSpaces:        "Este campo sólo puede contener letras con espacios",
  lettersNumbersWithSpaces: "Este campo sólo puede contener letras y números con espacios",
  integerOrDecimal:         "Este campo sólo puede contener números",
  "validDate(DD/MM/YYYY)":  "La fecha debe tener el formato DD/MM/YYYY"
}

export default validator;
