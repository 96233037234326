import { addresses as storeAddresses } from '@themes/store/frontend/locales/es/user/my-account/addresses';

export const addresses = {
  ...storeAddresses,
  defaultSelect:{
    notDefault:             'no es una dirección por defecto',
    defaultShipping:        'dirección de envío',
    defaultBilling:         'dirección de facturación',
    defaultShippingBilling: 'dirección de facturación y envío',
    label:                  'Elige una opción'
  },
  delete:               'Eliminar dirección',
  defaultBillingTitle:  'Dirección de facturación por defecto',
  defaultShippingTitle: 'Dirección de envío por defecto'
}

export default addresses;
