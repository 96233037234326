import general          from '@frontend/locales/es/general';

import validator        from '@frontend/locales/es/validator';
import searchBar        from '@frontend/locales/es/search-bar';
import navigationMenu   from '@frontend/locales/es/navigation-menu';
import newsletter       from '@frontend/locales/es/newsletter';
import contact          from '@frontend/locales/es/contact';
import searchResult     from '@frontend/locales/es/search-result';
import share            from '@frontend/locales/es/share';
import mail             from '@frontend/locales/es/mail';
import days             from '@frontend/locales/es/days';
import months           from '@frontend/locales/es/months';

export const es = {
  langCode: 'es',
  ...general,
  validator,
  searchBar,
  navigationMenu,
  newsletter,
  contact,
  searchResult,
  share,
  mail,
  days,
  months
}

export default es;
