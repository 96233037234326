export const rma = {
   notAvailable:    "No se puede procesar la devolución de forma automática, póngase en contacto con atención al cliente.",
   notAvailableMsg: "Puede escribirnos un email a ",
   title:           "Devolución",
   reason:          "Motivo de la devolución",
   qtyError:        "Uds. máx: ",
   product_title:   "Productos disponibles para devolver",
   submit:          "Solicitar devolución",
   submit_result:   "Hemos registrado su solicitud de devolución. Pronto recibirá un email con toda la información",
   cant_rma:        "No es posible generar una devolución a través de la web, por favor contacte con nuestro departamento de atención al cliente.",
   error_items:     "Debe seleccionar al menos un producto para devolver"
}

export default rma;
