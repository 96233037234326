export const days = {
  monday:     "Lunes",
  tuesday:    "Martes",
  wednesday:  "Miercoles",
  thursday:   "Jueves",
  friday:     "Viernes",
  saturday:   "Sabado",
  sunday:     "Domingo",
  mon:        "L",
  tue:        "M",
  wed:        "X",
  thu:        "J",
  fri:        "V",
  sat:        "S",
  sun:        "D"
}

export default days;
