import createAccount      from '@frontend/locales/es/user/create-account';
import signIn             from '@frontend/locales/es/user/sign-in';
import myAccount          from '@frontend/locales/es/user/my-account';
import forgottenPassword  from '@frontend/locales/es/user/forgotten-password';
import resetPassword      from '@frontend/locales/es/user/reset-password';
import invalidToken       from '@frontend/locales/es/user/invalid-token';
import activate           from '@frontend/locales/es/user/activate';

export const user = {
  createAccount,
  signIn,
  myAccount,
  forgottenPassword,
  resetPassword,
  invalidToken,
  activate
}

export default user;
