import {es as baseEs}       from '@themes/base/frontend/locales/es';
import productView          from '@frontend/locales/es/product-view';
import accountBanner        from '@frontend/locales/es/account-banner';
import miniCart             from '@frontend/locales/es/mini-cart';
import user                 from '@frontend/locales/es/user';
import coupons              from '@frontend/locales/es/coupons';
import filters              from '@frontend/locales/es/filters';
import footerNewsletter     from '@frontend/locales/es/footer-newsletter';
import footer               from '@frontend/locales/es/footer';
import addToWishlist        from '@frontend/locales/es/add-to-wishlist';
import deleteFromWishlist   from '@frontend/locales/es/delete-from-wishlist';
import categoryPage         from '@frontend/locales/es/category-page';
import checkout             from '@frontend/locales/es/checkout';
import relatedProducts      from '@frontend/locales/es/related-products';
import bestSellingProducts  from '@frontend/locales/es/best-selling-products';
import accountMenu          from '@frontend/locales/es/account-menu';
import countries            from '@frontend/locales/es/countries';
import genders              from '@frontend/locales/es/genders';
import regions              from '@frontend/locales/es/regions';
import contact              from '@frontend/locales/es/contact';
import general              from '@frontend/locales/es/general';
import cookies              from '@frontend/locales/es/cookies';
import socialLogin          from '@frontend/locales/es/social-login';

delete baseEs.mail;

export const es = {
  ...baseEs,
  productView,
  accountBanner,
  miniCart,
  user,
  coupons,
  filters,
  footerNewsletter,
  footer,
  addToWishlist,
  deleteFromWishlist,
  categoryPage,
  checkout,
  relatedProducts,
  bestSellingProducts,
  accountMenu,
  countries,
  genders,
  regions,
  contact,
  general,
  cookies,
  socialLogin,
}


/**
 * FROM BASE
 *
 * langCode
 * contact
 * searchBar
 * navigationMenu
 * contact,
 * share,
 * days
 * months
 * searchResult
 * days
 * months
 *
 */

/**
* REMOVED
*
* mail
*/

/**
 * EXTENDED
 *
 * validator
 * newsletter
 * general
 *
 */

export default es;
