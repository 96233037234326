export const map = {
  sp:   "España",
  co:   "Colombia",
  ec:    "Ecuador",
  mx:    "México",
  address: "Calle, zona o código postal",
  search: "Buscar",
  zeroResults: "No hemos encontrado ningún resultado. Prueba con otra dirección.",
  selected_store: "Tienda seleccionada"
}

export default map;
