export const checkout = {
  yourOrder:        'Su nº de pedido es: %1',
  confirmationText: 'Te enviaremos un email de confirmación con los detalles e información del envío.',
  continueBuying:   'Continuar comprando',
  verificationText: 'Puedes verificar el estado de tu pedido creando una cuenta.',
  createAccount:    'Crear una cuenta',
  failureText:      'Hubo un problema con su pedido. Por favor, inténtelo de nuevo',
  deliveryTime:     'por elegirnos, en 24/48 horas tendrás tu pedido en casa',
  confirmation:     'en unos minutos te enviaremos un email con la confirmación y el seguimiento de tu pedido',
  home:             'home',
  successTitle:     'Muchas gracias'
}

export default checkout;
