export const miniCart = {
  cart:               'Cesta',
  qty:                'Unidades',
  itemDelete:         'Eliminar',
  noItems:            'No tienes ningún producto en tu carrito',
  oneProductInCart:   'Articulo en la cesta',
  productsInCart:     'Artículos en la cesta',
  subTotal:           'Subtotal',
  subTotalDiscount:   'Con descuento',
  buttonCheckout:     'Finalizar compra',
  continueBuying:     'Continuar comprando'
}

export default miniCart;
