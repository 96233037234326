export const footer = {
  links:{
    logguedCustomers:{
      title: 'Usuarios registrados',
      items:{
        orderHistory:   'Historial de pedidos',
        myAccount:      'Mi cuenta',
        addressesBook:  'Libreta de direcciones'
      }
    },
    services:{
      title: 'Servicios',
      items:{
        legalAdvice:    'Aviso legal',
        privacyPolicy:  'Política de privacidad',
        shipmentPolicy: 'Política de envío y devoluciones',
        warranty:       'Garantías'
      }
    },
    information:{
      title: 'Información',
      items: {
        aboutUs:    'Sobre nosotros',
        contactUs:  'Contacta con nosotros'
      }
    }
  }
}

export default footer;
