import { myAccount as storeMyAccount }    from '@themes/store/frontend/locales/es/user/my-account';
import wishlistSection                    from '@frontend/locales/es/user/my-account/wishlist-section';
import pdf                                from '@frontend/locales/es/user/my-account/pdf';

export const myAccount = {
  ...storeMyAccount,
  wishlistSection,
  pdf,
  accountInfo:        'Información de contacto',
  welcome:            'hola %1!',
  editCustomer:       'Editar contraseña / información de la cuenta *',
  addAddress:         'Añadir dirección',
  editCustomer:       'editar perfil',
  aditionalEntries: 'Otras direcciones',
}

export default myAccount;
