export const addresses = {
  editTagTitle:         'Editar dirección',
  editMetaTitle:        'Editar dirección',
  addTagTitle:          'Añadir nueva dirección',
  addMetaTitle:         'Añadir nueva dirección',
  add:                  'Añadir nueva direccion',
  edit:                 'Editar dirección',
  firstnameLabel:       'Nombre',
  lastnameLabel:        'Apellidos',
  regionLabel:          'Provincia',
  countryLabel:         'País',
  postCodeLabel:        'Código Postal',
  cityLabel:            'Ciudad',
  streetLabel:          'Dirección',
  telephoneLabel:       'Teléfono',
  defaultBilling:       'Dirección de facturación predeterminada',
  defaultShipping:      'Dirección de envío predeterminada',
  notDefaultBilling:    'No tienes ninguna dirección de facturación predeterminada en tu libreta de direcciones.',
  notDefaultShipping:   'No tienes ninguna dirección de envío predeterminada en tu libreta de direcciones.',
  notOtherAddresses:    'No tiene otras direcciones en su libreta de direcciones.',
  notbillingAddresses:  'Aún no ha añadido ninguna dirección de facturación',
  notshippingAddresses: 'Aún no ha añadido ninguna dirección de envío'
}

export default addresses;
