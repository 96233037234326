export const tribe = {
  name: "Nombre y apellidos *",
  birth: "Fecha de nacimiento *",
  phone: "Teléfono *",
  town:  "Población",
  region:"Provincia",
  email: "Correo electrónico *",
  exp:   "¿Tienes experiencia como maquillador/a?",
  title: "únete a nuestra familia",
  yes:   "Sí",
  no:    "No",
  cv:    "Sube tu currículum en formato .pdf",
  file:  "archivo...",
  file_success: "Archivo subido correctamente",
  file_success_name: "Archivo %1 subido correctamente",
  he:    "He",
  success_message: "Hemos recibido sus datos, en breve nos pondremos en contacto con usted. Gracias!",
  terms: "leído y acepto",
  conditions: "las condiciones de la ",
  privacy:"política de privacidad. *",
  mandatory: "* Campos obligatorios",
  send:   "enviar",
  errors:{
    terms: "Por favor acepte las condiciones.",
    general: "Este campo es obligatorio"
  }
}

export default tribe;
