export const looks ={
  looks:{
    title:    "LOOKS",
    inspire:  "que inspiran",
    text1:     "La belleza no consiste en cubrir sino en destapar.",
    text2:     "Llevamos este lema por badera y por ello trabajamos para traerte un nuevo concepto, smart cosmetic, que se adapte a ti, a tus gustos y necesidades, para que te vuelvas a descubrir."
  }
}

export default looks;
