export const reviews = {
  review: 'Opiniones: ',
  count: '%1 opiniones',
  showMore: 'ver más',
  showLess: 'ver menos',
  addReview: {
    button: 'Dejar Opinión',
    title: 'Deja tu opinión',
    send: 'Enviar'
  }
}

export default reviews;
