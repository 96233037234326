export const blackFriday = {
  bestsellersTitle: "BEST SELLERS",
  bestsellersText: "los productos más deseados",
  packsTitle: "PACKS",
  packsText: "packs smart pensados por y para ti",
  packsUrl: "packs-regalo-maquillaje-cosmetica.html",
  outletTitle: "OUTLET",
  outletText: "últimas unidades",
  outletUrl: "outlet-cosmetica-etnia.html",
  makeupTitle: "MAKEUP",
  makeupUrl: "maquillaje-online.html",
  skincareTitle: "SKINCARE",
  skincareUrl: "cosmetica-online.html",
  fragancesTitle: "FRAGANCIAS",
  fragancesUrl: "perfumes-online.html",
  veganTitle: "VEGANOS",
  veganUrl: "productos-veganos.html",
  brushesTitle: "BROCHAS",
  brushesUrl: "accesorios-maquillaje.html",
  happyTitle: "HAPPY",
  happyUrl: "happy.html",
  seeAll: "ver todos los productos",
  wannaTalkTitle: "¿hablamos?",
  wannaTalkText: "Si tienes cualquier duda a la hora de escoger los productos que más van contigo déjate asesorar por nuestro equipo de expertos vía chat, Whatsapp, asesoría virtual, email o mensaje directo en nuestras RRSS",
  wannaTalkButton: "conecta con nosotros"
}

export default blackFriday;
