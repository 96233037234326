export const createAccount = {
  tagTitle:         'Crea tu cuenta',
  metaTitle:        'Crea tu cuenta',
  success:          'Cuenta creada con éxito',
  pageTitle:        'Nuevo Usuario',
  pageDescription:  'Crear nueva cuenta de cliente',
  firstName:        'Nombre',
  lastName:         'Apellidos',
  birthday:         'Fecha de nacimiento',
  subscribe:        'Suscribete a nuestro boletín',
  gender:           'Sexo',
  nif:              'NIF/NIE/CIF',
  address:          'Dirección',
  telephone:        'Teléfono',
  city:             'Población',
  postCode:         'C.P.',
  region:           'Provincia',
  country:          'País',
  personalInfo:     'Información Personal',
  accessInfo:       'Información de acceso',
  email:            'Email',
  password:         'Contraseña',
  confirmPassword:  'Confirmar Contraseña',
  buttonLabel:      'Crear Cuenta',
  linkSignin:       'Ya soy usuario',
  accept:           'Acepto la ',
  privacyLink:      'política de privacidad',
  newsletter:       'Suscribirme a la newsletter'
}

export default createAccount;
