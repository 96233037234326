export const contactInfo = {
  sectionTitle:           'Información de contacto',
  nameLabel:              'Nombre',
  emailLabel:             'Email',
  male:                   'Hombre',
  female:                 'Mujer',
  ageLabel:               'Edad',
  taxvatLabel:            'DNI',
  genderLabel:            'Sexo',
  birthdayLabel:          'Fecha de nacimiento',
  dobLabel:               'Fecha de nacimiento',
  telephoneLabel:         'Teléfono',
  regionLabel:            'Provincia',
  countryLabel:           'País',
  edit:                   'Editar Usuario',
  save:                   'Guardar Cambios',
  cancel:                 'Cancelar'
}

export default contactInfo;
