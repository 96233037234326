export const sideBarNewsletter = {
  recaptchaError:     'Es necesario validar el reCaptcha',
  privacyError:       'Es necesario aceptar la política de privacidad',
  subscribe:          'Newsletter',
  emailPlaceholder:   'Tu email',
  subscriptionError:  'Error al suscribirte a la newsletter. Revisa tu e-mail y vuelve a intentarlo más tarde.',
  send:               'Suscribirse',
  accept:             'Acepto la ',
  privacyLink:        'política de privacidad'
}

export default sideBarNewsletter;
