export const menuFooter = {
  shops:      "Tiendas",
  blog:       "Blog",
  about:      "About",
  conditions: "Condiciones",
  lovely:     "Club Lovely",
  legalBase:  "Bases legales",
  jobs:       "Empleo",
  legalWarning: "Aviso legal",
  faq:          "faq",
  shops:        "Tiendas",
  terms:        "Terminos y condiciones"
}

export default menuFooter;
