export const orderDetail = {
  pageTitle:        'Pedido',
  infoTitle:        'Información del pedido',
  itemsTitle:       'Artículos solicitados',
  orderId:          'Nº de pedido',
  date:             'Fecha',
  status:           'Estado',
  orderInfo:        'Detalles del pedido',
  shippingAddress:  'Dirección de envío',
  shippingMethod:   'Metodo de Envío',
  billingAddress:   'Dirección de Facturación',
  billingMethod:    'Metodo de Pago',
  orderItems:{
    name:       'Nombre del producto',
    sku:        'SKU',
    price:      'Precio',
    qty:        'Cant.',
    subtotal:  'Subtotal'
  },
  orderTotals:{
    subTotal: 'Subtotal',
    shippingTax:  'Gastos de envío y manipulación',
    tax: 'Impuestos',
    total: 'Total'
  },
  pdf:{
    download: 'Descargar pdf',
    loading:  'Preparando pdf...'
  }
}

export default orderDetail;
