export const blog = {
  mainTitle:                'Blog Etnia Cosmetics: Últimas tendencias en maquillaje y cosmética',
  mainPageTitle:            'Los mejores artículos de maquillaje y cosmética - Etnia Cosmetics Blog',
  mainPageMetaDescription:  'Descubre las últimas tendencias de maquillaje y cosmética en el Blog de Etnia Cosmetics. Trucos y consejos de belleza para verte aún más guapa. ¡Entra ya!',
  categories:               'categorías',
  menu_text:                'Encuentra lo que buscas, resuelve tus dudas y conoce los mejores tips para sacar el máximo partido a tus productos',
  recentPosts:              'Entradas más recientes',
  latest:                   'últimos posts',
  latest_message:           'descubre todo lo que no sábias sobre la cosmética con nosotras',
  read_more:                'leer más'
}

export default blog;
