export const info = {
  vegan:                  'vegano',
  inStock:                'En stock',
  notStock:               'No hay stock',
  qty:                    'Cantidad',
  description:            'Descripción del producto:',
  composition:            'Composición',
  howToUse:               'Modo de empleo: ',
  howToUse_mobile:        'Modo de empleo',
  help:                   'Te ayudamos: ',
  help_mobile:            'Te ayudamos',
  composition:            'Composición',
  opinions:               'Opiniones',
  operatingInstructions:  'Modo de empleo',
  shippingReturns:        'Envíos y devoluciones',
  bundleIncludes:         'El producto incluye: ',
  selectValue:            'Selecciona un valor',
  chooseVariation:        'Elige un producto: ',
  noOptionChosen:         'Por favor elige una opción',
  just:                   '¡Sólo quedan ',
  units_left:             ' unidades!',
  preOrderWarning:        'Este producto está disponible solo para reserva. Si haces la compra ahora te lo enviaremos a partir del 8 de marzo.'
}

export default info;
