export const stock = {
    noStock: "Actualmente no hay stock disponible.",
    outOfStock: "fuera de stock",
    outOfStockProduct: "Producto fuera de stock",
    mobileMessage: "Déjanos tu mail y te avisaremos cuando esté disponible",
    mobilePlaceholder: "dirección de mail",
    sendProductList: "avísame cuando vuelva",
    mobileSend: "enviar",
    placeholder: "déjanos tu email y te avisaremos cuando esté disponible",
    giveMail: "Puedes dejarnos tu email y te avisaremos cuando vuelva a haber stock.",
    invalidEmail: "El correo ingresado no es valido, por favor introduce otra dirección de correo electornico",
    send: "avísame",
    mobileSendWarn: "avísame"
}

export default stock;
