import addresses      from '@frontend/locales/es/user/my-account/addresses';
import contactInfo    from '@frontend/locales/es/user/my-account/contact-info';
import orderDetail    from '@frontend/locales/es/user/my-account/order-detail';
import orders         from '@frontend/locales/es/user/my-account/orders';

export const myAccount = {
  addresses,
  contactInfo,
  orderDetail,
  orders,
  tagTitle:           'Mi cuenta',
  metaTitle:          'Mi cuenta',
  pageTitle:          'Mi cuenta',
  me:                 'Cuenta',
  myAdresses:         'Libreta de direcciones',
  myOrders:           'Mis Pedidos',
  accountInfo:        'Datos Personales',
  addressInfo:        'Libreta de direcciones',
  wishlist:           'Wishlist',
  emptyWishlist:      'Aún no has añadido ningún producto a tu wishlist',
  signOut:            'Cerrar Sesión',
  billingAddresses:   'Dirección de facturación',
  shippingAddresses:  'Dirección de envío',
  edit:               'Editar',
  add:                'Añadir',
  defaultAddresses:   'Direcciones predeterminadas',
  aditionalEntries:   'Entradas de dirección adicionales'
}

export default myAccount;
