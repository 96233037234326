export const appointment = {
  title: "pide tu cita previa",
  subtitle: "para maquillarte con nuestras makeup artist",
  service: "Servicio*",
  services: {
    virtual: "Cita virtual",
    appointment: "Cita de maquillaje",
    course: "Curso de maquillaje",
    physicalStore: "Cita en tienda física"
  },
  store: "Elige tu tienda*",
  webStore: "Web",
  name: "Nombre y apellidos*",
  phone: "Teléfono*",
  date: "Fecha*",
  hour: "Hora*",
  dateInfo: "La cita propuesta será confirmada por la tienda en función a la disponibilidad de la misma",
  email: "Correo electrónico*",
  success_message: "Hemos recibido sus datos, en breve nos pondremos en contacto con usted. Gracias!",
  he:    "He",
  terms: "leído y acepto",
  conditions: " las condiciones de la ",
  privacy:"política de privacidad. *",
  mandatory: "* Campos obligatorios",
  send:   "reserva tu cita",
  errors:{
    terms: "Por favor acepte las condiciones.",
    general: "Este campo es obligatorio"
  },
  appointment: "Cita previa"
}

export default appointment;
