export const months = {
    month0:     "Enero",
    month1:     "Febrero",
    month2:     "Marzo",
    month3:     "Abril",
    month4:     "Mayo",
    month5:     "Junio",
    month6:     "Julio",
    month7:     "Agosto",
    month8:     "Septiembre",
    month9:     "Octubre",
    month10:    "Noviembre",
    month11:    "Diciembre",
    january:    "Enero",
    february:   "Febrero",
    march:      "Marzo",
    april:      "Abril",
    may:        "Mayo",
    june:       "Junio",
    july:       "Julio",
    august:     "Agosto",
    september:  "Septiembre",
    october:    "Octubre",
    november:   "Noviembre",
    december:   "Diciembre"
  }

export default months;
