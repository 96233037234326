import { signIn as storeSignIn } from '@themes/store/frontend/locales/es/user/sign-in';

export const signIn = {
  ...storeSignIn,
  pageTitle:              'inicia tu sesión',
  sectionDescription:     'para añadir tus productos favoritos a la wish list y conocer todas las ofertas',
  buttonLabel:            'entrar',
  email:                  'dirección de mail',
  createAccount:          '¿eres nueva?',
  forgottenPassword:      '¿has olvidado la contraseña?',
  showPassword:           'mostrar',
  hidePassword:           'ocultar'
}

export default signIn;
