export const contact = {
  location: 'Donde estamos',
  info: 'Contacto',
  form: 'Escríbenos',
  name: "Nombre y apellidos",
  birth: "Fecha de nacimiento",
  phone: "Teléfono",
  town:  "Población",
  region:"Provincia",
  email: "Correo electrónico",
}

export default contact;
