export const signIn = {
  tagTitle:               'Inicio de sesión',
  metaTitle:              'Inicio de sesión',
  pageTitle:              'Usuario Neoactives',
  sectionDescription:     'Si tienes una cuenta, inicia sesión con tu dirección de correo electrónico',
  buttonLabel:            'Entrar',
  email:                  'Correo electrónico',
  password:               'Contraseña',
  newCustomer:            'Nuevo Usuario',
  createAccount:          'Crear cuenta',
  createAccountBenefits:  'Crea tu cuenta de usuario en Neoactives',
  signIn:                 'Iniciar sesión',
  forgottenPassword:      'Olvidé mi contraseña'
}

export default signIn;
