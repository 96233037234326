export const wishlistSection = {
  title:              'wish list',
  welcomeMessage:     'bienvenida a tu lista de deseos.',
  notificationMessage:'Puedes activar las notificaciones para que te avisemos a tu mail cuando alguno de tus favoritos se encuentre en promoción.',
  enableNotification: 'activar notificaciones',
  summarySubtitle:    'bienvenida a tu lista de deseos',
  youHave:            'tienes',
  productInList:      'productos en tu lista',
  total:              'total',
  buyNow:             'comprar ahora',
  advantage:          'aprovecha nuestras promociones',
  advantageSecond:    'para llevarte a casa tus productos favoritos',
  keepBuying:         'seguir comprando',
  shareList:          'compartir mi wish list'
}

export default wishlistSection;
