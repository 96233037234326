import { es }       from '@frontend/locales/es';

class i18n {
  constructor(language = es){
    i18n.initLang(language);

    this.t        = i18n.translate;
    this.initLang = i18n.initLang;
  }

  static initLang(language = es){
    i18n.lang = language;
  }

  static translate(path,params = []){
    let translation = i18n.langPathConversor(i18n.lang,path);

    if(params && params.length){
      params.map((param, index) => {
        index       = index + 1;
        translation = translation.replace(`%${index}`,param);
        return translation;
      });
    }
    return translation;
  }

  static langPathConversor(object,string){
    // convert indexes to properties
    string    = string.replace(/\[(\w+)\]/g, '.$1');
    // strip a leading dot
    string    = string.replace(/^\./, '');

    let step  = string.split('.');

    for (let i = 0, n = step.length; i < n; ++i) {
      let relativePath = step[i];

      if (relativePath in object) {
        object = object[relativePath];
      } else {
        return;
      }
    }
    return object;
  }

}


export default i18n;
