export const forgottenPassword = {
  tagTitle:       'Recuperar contraseña',
  metaTitle:      'Recuperar contraseña',
  pageTitle:      'Recuperar contraseña',
  description:    'Introduzca su dirección de correo electrónico para recibir un enlace de restablecimiento de contraseña.',
  returnToLogin:  'Volver a Iniciar sesión',
  send:           'Enviar',
  error:          'Error al enviar el email, verifique que el correo existe e inténtelo de nuevo.',
  success:        'Email enviado, por favor revise su bandeja de entrada para resetear la contraseña',
  button:{
    send:    'Enviar',
    sent:    'Enviado',
    sending: 'Enviando'
  }
}

export default forgottenPassword;
