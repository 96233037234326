import { miniCart as storeMiniCart } from '@themes/store/frontend/locales/es/mini-cart';

export const miniCart = {
  ...storeMiniCart,
  cart:               'Mi compra',
  userWelcome:        '%1, bienvenida a tu cesta',
  description:        'confirma tu pedido y en 24/48h lo tienes en casa',
  subTotal:           'resumen de productos',
  subTotalDiscount:   'resumen de productos',
  buttonCheckout:     'Pasar por caja',
  continueBuying:     'Continuar comprando',
  shippingTax:        'gastos de envío',
  shippingValue:      'haz login o introduce tu dirección',
  discount:           'descuento',
  freeShipping:       'gratis',
  total:              'total',
  itemAdded:          'Añadido a tu cesta',
  goToCart:           'ir a mi cesta',
  goToProducts:       'ver productos',
  wishlistTitle:      'todo del 20% al 40%',
  wishlistSubTitle:   'para que compres lo que necesites',
  wishlistButtonOne:  'tu wishlist',
  wishlistButtonTwo:  'añadir al carrito',
  slow:               'slow friday',
  toCartWish:         'tu wishlist al carrito',
  wishlistMessOne:    'Sabemos que has creado una lista de deseos consciente.',
  wishlistMessTwo:    'Es hora de volcar tu wishlist al carrito para hacerte con tus',
  wishlistMessThree:  'productos favoritos con el mayor descuento del año.',
  noItems:            '¡Todavía no tienes ningún producto en tu carrito!'
}

export default miniCart;
