export const newsletter ={
  recaptchaError:     'Es necesario validar el reCaptcha',
  privacyError:       'Es necesario aceptar la política de privacidad',
  subscribe:          'inscríbete en nuestra newsletter',
  subscribeInfo:      'para estar al día de todas nuestras promociones y descuentos',
  emailPlaceholder:   'dirección de mail',
  subscriptionError:  'Error al suscribirte a la newsletter. Revisa tu e-mail y vuelve a intentarlo más tarde.',
  send:               'Enviar',
  accept:             'Acepto la ',
  privacyLink:        'política de privacidad',
  rscSubscribe:       '¿necesitas una dosis de actitud?',
  rscEmailPlaceholder:'déjanos tu email',
  rscSend:            '¡únete al plan actitud!'
}

export default newsletter;
