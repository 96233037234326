import getLooksQuery   from "@frontend/services/queries/getLooks.graphql"

export const getLooks = ({client}) =>{
  return async (dispatch) => {
    let looks = await client.query({
      query: getLooksQuery
    })
    .then(({ data })=>{
      return (data);
    });
    dispatch(setLooks(looks));
    return looks;
  }
}
export const setLooks = (looks) => {
  return {
    type: "SET_LOOKS",
    looks
  }
}
