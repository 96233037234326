
export const ethicalForm = {
	relationship: "Relación con la organización",
	behaviorToCommunicate: "Tipología de la conducta a comunicar conforme a la Dir. (UE) 2019/1937 y normativa estatal",
	anonymous: "¿Desea que la comunicación sea anónima?",
	name: "Nombre y apellidos",
	email: "Email",
	phone: "Teléfono",
	description: "Descripción",
	date: "Fecha de los hechos",
	witnesses: "¿Existen testigos?",
	witnessesList: "Enumera los testigos",
	evidences: "Archivo (evidencias)",
	yes: "Si",
	no: 'No',
	internal: "Interna",
	external: "Externa",
	publicProcurement: "Contratación pública",
	financialServices: "Servicios, productos y mercados financieros",
	moneyLaundering: "Blanqueo de capitales o financiación del terrorismo",
	productSafety: "Seguridad y conformidad de los productos comercializados",
	safetyTransport: "Seguridad del transporte",
	envitonmentalProtection: "Protección del medio ambiente",
	nuclearSafety: "Protección frente a radiaciones y seguridad nuclear",
	animalSafety: "Seguridad de los alimentos y los piensos, la sanidad animal y bienestar de los animales",
	publicHealth: "Salud pública",
	protectionConsumer: "Protección de los consumidores",
	privacyData: "Protección de la privacidad y de los datos personales, y seguridad de las redes y los sistemas de información",
	financialInterest: "Intereses financieros de la Unión y mercado interior",
	corruption: "Corrupción pública o privada",
	harassment: "Acoso laboral o sexual",
	intellectualProperty: "Propiedad industrial o intelectual",
	freeCompetition: "Libre competencia",
	subsidies: "Subvenciones, Hacienda Pública o Seguridad Social",
	humanRights: "Integridad moral o los Derechos Humanos",
	bribery: "Cohecho",
	influencePeddling: "Tráfico de influencias",
	interestConflicts: "Conflictos de intereses",
	occupationalHazards: "Prevención de riesgos laborales",
	other: "Otras infracciones administrativas graves o muy graves",
	success: "Tu mensaje se ha enviado correctamente",
	error: "Ha habido un error en el envío del mensjae, inténtalo de nuevo más tarde",
	introduction: "El sistema interno de información te permite reportar cualquier actuación que suponga una efectiva limitación de los derechos y garantías previstos en la ",
	legalLink: "L 2/2023",
	legals: ". Si presentas una comunicación, incluso aunque no sea de manera anónima, debes conocer que queda preservada tu identidad, que no puede ser comunicada a terceros, y garantizada la confidencialidad de los datos correspondientes a las personas afectadas y a cualquier tercero que se mencione en la información suministrada. Las revelaciones que deban hacerse quedan sujetas a salvaguardas establecidas en la normativa aplicable."
}

export default ethicalForm;
  
