import { categoryPage as storeCategoryPage } from '@themes/store/frontend/locales/es/category-page';

export const categoryPage = {
  ...storeCategoryPage,
  rawEssenceTitle: "The raw essence.",
  rawEssenceSubtitle: "Una introspección a través del olfato, una ruta por dentro de nosotros mismos.",
  capilarTitle: "HONEST",
  capilarSubtitleFirstPart: "daily",
  capilarSubtitleSecondPart: "hair care",
  capilarText: "recupera tu cabello con la nueva línea",

}

export default categoryPage;
