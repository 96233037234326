import { orders as storeOrders } from '@themes/store/frontend/locales/es/user/my-account/orders';

export const orders = {
  ...storeOrders,
  state:              'Estado',
  repeat:             'repetir pedido',
  return:             'devolver pedido',
  // NOTE: next translates are magento DB status
  canceled:           'Cancelado',
  closed:             'Cerrado',
  complete:           'Completado',
  complete_delivered: 'Completado y entregado',
  payment_review:     'Pago en revisión',
  pending:            'Pendiente',
  "Pending Payment":  'Pendiente de pago',
  pending_payment:    'Pendiente de pago',
  processing:         'En proceso',
  shipped:            'Enviado',
  waiting_payment:    'En espera de pago'
}

export default orders;
