export const safeBuy = {
    label:                'compra segura',
    paymentMethodsTitle:  'Múltiples formas de pago',
    paymentMethods1:      'Visa y mastercard, paypal, bizum',
    paymentMethods2:      'Pago fraccionado en 3, 6 o 12 meses',
    freeShippingTitle:    'Envíos gratis',
    freeShipping1:        'A partir de 40€ (península, Baleares y Portugal)',
    freeShipping2:        'A partir de 60€ (Canarias, Ceuta y Melilla)',
    freeShipping3:        'Clientes lovely a partir de 25€',
    freeShipping4:        'UE - consultar en condiciones generales',
    deliveryTimeTitle:    'Envíos en 1-2 días laborables* desde 3,50*',
    deliveryTime1:        'Puede extenderse hasta 5 días laborables en períodos',
    deliveryTime2:        'de promoción y post promoción.',
    deliveryTime3:        'Envíos a EU'
  }
  
  export default safeBuy;
  