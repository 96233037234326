export const orders = {
  tagTitle:   'Mis pedidos',
  metaTitle:  'Mis pedidos',
  notOrders:  'No tiene pedidos asociados a su cuenta.',
  orderId:    'Nº de pedido',
  date:       'Fecha',
  sendTo:     'Enviar a',
  total:      'Total del pedido',
  status:     'Estado',
  actions:    'Accion',
  detail:     'Ver pedido',
  statusValues:{
    pending:    'Pendiente',
    canceled:   'Cancelado',
    closed:     'Cerrado',
    complete:   'Completado',
    holded:     'Retenido',
    processing: 'En proceso'
  }
}

export default orders;
