export const cookies = {
  theTeam:            "En ",
  andOtherCompanies:  " utilizamos cookies propias y de terceros para fines analíticos y para mostrarte publicidad personalizada en base a un perfil elaborado a partir de tus hábitos de navegación (por ejemplo, páginas visitadas). Puedes obtener más información y configurar tus preferencias ",
  forMoreInfo:        " Para más información puedes visitar nuestra ",
  link:               "AQUÍ",
  acceptButton:       "Aceptar",
  cookiesPolicy:      "Política de cookies",
  configuration:      "Configuración de cookies",
  seeCookiesPolicy:   "Ver Política de Cookies",
  updateConfig:       "Ajustes de cookies",
  functional: {
    title:  'Cookies funcionales/técnicas',
    text:   'Necesarias para mostrar correctamente la página web y garantizar el correcto funcionamiento del sitio. Te permiten navegar en nuestro sitio web y utilizar las diferentes opciones o servicios que tiene. Con ellas podemos, por ejemplo, controlar el tráfico y la comunicación de datos, identificarte durante la sesión, darte acceso a partes de acceso restringido, y utilizar elementos de seguridad durante tu navegación.'
  },
  analytical: {
    title:  'Cookies analíticas',
    text:   'Se utilizan para analizar el comportamiento de los usuarios de forma agregada y anónima incluyendo el número de visitantes a la web y a diferentes páginas internas, la procedencia de las visita, día y hora, plataforma, palabras de búsqueda que utiliza un usuario para encontrar su contenido deseado. Nos ofrecen información estadística sobre el comportamiento de los diferentes usuarios en nuestra página web para introducir mejoras en función del análisis de los datos sobre el uso de productos y/o servicios en la página web.'
  },
  advertising: {
    title:  'Cookies de publicidad',
    text:   'Nos permiten la gestión de los espacios publicitarios incluidos en nuestra página web, en base a criterios como el contenido mostrado o la frecuencia en la que se muestran los anuncios.'
  },
  basic: {
    title:  'Cookies imprescindibles',
    text:   'Necesitamos estas cookies para que nuestra página web pueda funcionar de manera correcta. Nos ayuda a prevenir cualquier error durante la navegación o permitir que realices una compra. Así podremos mejorar la calidad de nuestro servicio.'
  },
  acceptSelection:    "Aceptar selección",
  rejectAll:          "Rechazar todas",
  session: {
    oneYear:          "Sesión / 1 año",
    halfHourTwoYears: "De 30 minutos a 2 años"
  }
}

export default cookies;
