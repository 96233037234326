export const mail = {
  label:             'Mail',
  subjectDesc:       'Asunto:',
  subjectInfo:       '[RE] Somos Ezenit a tope ahí jaja',
  destinationDesc:   'A:',
  destinationInfo:   'Paco Johnes',
  copyDesc:          'CC:',
  copyInfo:          'Paca Garte, Ana Tomía, Soila Cerdà',
  mailText:          'Hola Dolores Delano, \n Te paso un correo con lo que le pediste a Marco Gol Saludos, \n  Igor Dito',
  buttonDownload:    'Descargar archivos',
  attached:          'Adjunto.jpg',
  buttonDraft:       'Nuevo borrador'
}

export default mail;
