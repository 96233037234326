export const theme = {
  cookies: {
    this_web: 'Esta web usa cookies.',
    keep_nav: 'Para seguir navegando debe consentir nuestro uso de cookies',
    ok: 'aceptar',
    link: 'política de cookies',
    vegan: 'vegano'
  },
  days: 'días',
  hours: 'horas',
  minutes: 'minutos'
}

export default theme;
