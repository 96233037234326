import { general as storeGeneral }    from '@themes/store/frontend/locales/es/general';

export const general = {
  ...storeGeneral,
  uploadProgress: 'Subiendo archivo: %1 % completado',
  addToCart: 'añadir a la cesta',
  preOrder: 'pre order',
  seeProduct: 'Ver Producto',
  buy: 'Comprar',
  vegan: 'vegano',
  addToCartMobile: 'añadir',
  noStock: 'producto no disponible',
  fileError: 'Es necesario que incluya un archivo'
}

export default general;
